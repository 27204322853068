.articleV1 {
	text-align: center;
	color: #000;
	font-family: 'Cormorant Garamond', serif;

	.category {
		margin-top: 20px;
		font-weight: 700;
		display: block;
		font-size: 16px;
		color: #000;
	}

	h3 {
		margin-top: 20px;
		margin-bottom: 0;

		a {
			color: #000;
			font-weight: 600;
		}
	} 

	span {
	    font-size: 16px;
	    margin-top: 20px;
	    display: block;
	}
}

.articleV2 .content {
	background: #fff;
	text-align: center;
	padding: 20px;

	h3 {
		margin-top: 0;
	}

	h3 a {
		color: #000;
	}

	a.link {
		color: #000;
		text-decoration: underline;
    	text-decoration-color: #eeb38c;
    	color: #000;
    	text-transform: uppercase;
    	font-weight: 400;
	}
}

.articleV3 {
	color: #000;
	font-family: 'Cormorant Garamond', serif;
	margin-top: 50px;
	padding-bottom: 50px;
	border-bottom: 1px solid #eee;

	.featuredImage {
		width: 50%;
		float: left;
		margin-right: 15px;
	}

	.category {
		font-weight: 700;
		display: block;
		font-size: 16px;
		color: #000;
	}

	h3 {
		margin: 0;

		a {
			color: #000;
			font-weight: 600;
		}
	} 

	span {
	    font-size: 16px;
	    margin-top: 0;
	    margin-bottom: 10px;
	    display: block;
	}

	p {
		margin-top: 20px;
		font-size: 14px;
	}
}

article.article, article.page {
	color: #000;

	.category {
		font-weight: 700;
		display: block;
		font-size: 16px;
		color: #000;
	}

	.imgFeatured {
		margin-top: 20px;
	}

	.content {
		margin-top: 20px;

		p {
			letter-spacing: 1px;
		}
	}

	img {
		width: 100%;
	}

	h1.title {
		margin: 0;

		a {
			color: #000;
			font-weight: 600;
		}
	} 

	span {
	    font-size: 16px;
	    margin-top: 30px;
	    display: block;
	}
}


/* Large Monitors */
@media (min-width: 1200px) { 

}

/* Small Monitors */
@media (min-width: 992px) and (max-width: 1200px) {


}

/* Tablets */
@media (max-width: 992px) {
	

}

/* Mobiles */
@media (max-width: 767px) {
	.articleV1 {
		margin-bottom: 30px;

		span {
			margin-top: 0;
		}

		img {
			width: 100%;
		}
	}

	.articleV2 {
		margin-bottom: 30px;
	}

	.articleV3 .featuredImage {
		width: 100%;
		margin-bottom: 20px;
	}

	.title {
		margin-bottom: 50px;
	}

	.sidebar {
		margin-top: 50px;
	}

	article.article span, article.page span {
		margin-top: 30px;
	}

	#mainNavigation {
		width: 100%;
		li {
			display: block;
			border-bottom: 1px solid #eee;
		}
	}

	.navbar-toggle {
		margin-top: -45px;
	}
}