@import url('https://fonts.googleapis.com/css?family=Cormorant+Garamond:600,700|Lato:400,700');

.mt2 {
	margin-top: 20px;
}

.btn {
	transition: .5s all;
}

.btn-primary {
	border: 2px solid $active;
	border-radius: 0;
	background: none;
	color: $active;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
	color: #fff;
	background: $active;
	border: 2px solid $active;
}

.title {
	margin-top: 0;
	margin-bottom: 70px;
	font-size: 28px;
	letter-spacing: 2px;
	font-weight: 300;
	text-transform: uppercase;
    font-family: 'Cormorant Garamond', serif;
    position: relative;
}

.title:after {
	content: '';
	width: 100px;
	height: 2px;
	background: $active;
	position: absolute;
    bottom: -10px;
	margin: 0 auto;
	left: 0;
	right: 0;
}

html, body {
	font-family: 'Lato'
}

h1,h2,h3,h4,h5,h6 {
	font-family: 'Cormorant Garamond', serif;
}

.sectionPadding {
	padding: 50px 0;
}

.graySection {
	background: #F3F2F1;
}

.widget {
	padding: 15px;
	margin-bottom: 30px;

	h4 {
		text-align: center;
		margin-top: 0;
		margin-bottom: 30px;
		font-size: 20px;
		font-weight: 700;
		position: relative;
	}

	h4:after {
		content: '';
	    width: 30px;
	    height: 2px;
	    background: #DE681A;
	    position: absolute;
	    left: 0;
	    right: 0;
	    text-align: center;
	    margin: 0 auto;
        bottom: -10px;
	}

	.articleV2 {
		margin-bottom: 30px;
	}

	.articleV2:last-child {
		margin-bottom: 0;
	}
}

.socialLinks a {
	color: #000;
	margin-right: 10px;
}

.socialLinks a:list-child {
	margin-right: 0;
}

footer {
	background: #000;
	padding: 20px;
	color: #fff;
}