.upperHeader {
	background: #000;
	text-align: center;
	color: #fff;
	padding: 20px 15px;
	
	h1 {
		margin: 0;
		font-family: 'Cormorant Garamond', serif;
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: 5px;
		font-size: 42px;

		a, a:hover {
			color: #fff;
			text-decoration: none;
		}
	}

}

.bottomHeader {
	border-bottom: 2px solid #f3f2f1;
	text-align: center;

	ul {
		padding: 0;
		margin: 0;

		li {
			list-style: none;
			display: inline-block;

			a {
				display: block;
				padding: 15px 20px;
				text-transform: uppercase;
				transition: .5s all;
				font-family: 'Lato', sans-serif;
				font-weight: 700;
				font-size: 12px;
				color: #000;
				letter-spacing: 2px;
			}

			a:hover {
				text-decoration: none;
				color: $active;
			}
		}
	}
}